require('aframe');
require('aframe-extras');

window.addEventListener('load', (event) => {
  var scene = document.querySelector('a-scene');
  scene.addEventListener('enter-vr', (event) => {
    document.getElementById('artist-link').style.display = "none"
  });
  scene.addEventListener('exit-vr', (event) => {
    document.getElementById('artist-link').style.display = "block"
  });
})

AFRAME.registerComponent('raycaster-listen', {
  init: function () {
    // Use events to figure out what raycaster is listening so we don't have to
    // hardcode the raycaster.
    this.cameraRig = document.querySelector('#camera-rig') // Assign street to a variable
    this.el.addEventListener('raycaster-intersected', evt => {
      if (evt.detail.el.id === "laser") {
        this.raycaster = evt.detail.el;
      }
    });
    this.el.addEventListener('raycaster-intersected-cleared', evt => {
      if (evt.detail.el.id === "laser") {
        this.raycaster = null;
      }
    });

    this.tick = AFRAME.utils.throttleTick(this.tick, 100, this);
  },
  tick: function () {
    if (!this.raycaster) { return; }  // Not intersecting.
    let intersection = this.raycaster.components.raycaster.getIntersection(this.el);
    if (!intersection) { return; }
    // if (Math.abs(this.cameraRig.object3D.position.y - intersection.point.y) < 3.2) {
    if (intersection.point.y > -20 && intersection.point.y < 1) {
      // console.log(this.cameraRig.object3D.position.y - intersection.point.y)
      // let increment = (1.6 - (this.cameraRig.object3D.position.y - intersection.point.y))
      this.cameraRig.object3D.position.y = intersection.point.y + 1.6;
    }
  }
});

AFRAME.registerComponent('artwork-listener', {
  init: function () {
    // Use events to figure out what raycaster is listening so we don't have to
    // hardcode the raycaster.
    this.intersectedArtwork;
    this.intersectionTime;
    this.artistLink = document.getElementById('artist-link')
    this.el.addEventListener('raycaster-intersected', evt => {
      this.raycaster = evt.detail.el;
    });
    this.el.addEventListener('raycaster-intersected-cleared', evt => {
      this.raycaster = null;
      this.intersectedArtwork = null;
      this.artistLink.style.bottom = "-50px";
    });

    this.tick = AFRAME.utils.throttleTick(this.tick, 100, this);
  },
  tick: function () {
    if (!this.raycaster) { return; }  // Not intersecting.
    let intersection = this.raycaster.components.raycaster.getIntersection(this.el);
    if (!intersection) { return; }

    if (this.intersectedArtwork == intersection.object.material.name) {
      // if (new Date().getTime() - this.intersectionTime.getTime() > 2500) {
        var filteredArtists = artistData.filter((d) => {
          return d.id == intersection.object.material.name
        });
        if (filteredArtists.length > 0) {
          this.artistLink.textContent = filteredArtists[0].title
          this.artistLink.href = filteredArtists[0].url
          this.artistLink.style.bottom = "100px";
        }
      // }
    } else {
      this.intersectedArtwork = intersection.object.material.name
      this.intersectionTime = new Date()
      this.artistLink.style.bottom = "-50px";
    }
  }
});


AFRAME.registerComponent('modify-materials', {
  init: function () {
    // Wait for model to load.
    this.el.addEventListener('model-loaded', () => {
      // Grab the mesh / scene.
      const obj = this.el.getObject3D('mesh');
      obj.traverse(node => {
        if (node.material) {
          node.material.color.set('white');
        }
      });
    });
  }
});
